<script setup lang="ts">
const links = [
  {
    label: 'Contact',
    children: [
      {
        label: 'uimire.com',
        to: 'https://uimire.com',
        target: '_blank'
      },
      {
        label: 'contact@uimire.com',
        to: 'mailto:contact@uimire.com'
      },
    ]
  },
  {
    label: 'Legal',
    children: [
      {
        label: 'Termeni si conditii'
      },
      {
        label: 'Politica de confidentialitate'
      },
    ]
  },
]
</script>

<template>
  <UFooter>
    <template #top>
      <UFooterColumns :links="links" />
    </template>

    <template #left>
      <p class="text-gray-500 dark:text-gray-400 text-sm">
        UIMIRE © {{ new Date().getFullYear() }}. Toate drepturile rezervate.
      </p>
    </template>

    <template #right>
      <UColorModeButton size="sm" />

      <UButton to="https://uimire.com" target="_blank" icon="i-heroicons-building-office" aria-label="uimire"
        color="gray" variant="ghost" />
    </template>
  </UFooter>
</template>
