<template>
  <ULink class="flex items-center gap-1" to="/">
    <Icon class="icon" name="i-heroicons-calculator" />
    TAX
    <UBadge class="badge p-0" label=".uimire.com" variant="ghost" />
  </ULink>
</template>

<style>
.icon {
  color: rgb(var(--color-primary-DEFAULT));
}

.badge {
  margin-top: 5px;
  color: rgb(var(--color-primary-DEFAULT));
}

@media print {
  .icon {
    color: black;
  }

  .badge {
    margin-top: 3px;
    color: black;
  }
}
</style>