<script setup lang="ts">
useHead({
  meta: [
    { name: 'viewport', content: 'width=device-width, initial-scale=1' }
  ],
  link: [
    { rel: 'icon', href: '/favicon.ico' }
  ],
  htmlAttrs: {
    lang: 'en'
  }
})

useSeoMeta({
  ogImage: 'https://uimire/social-card.png',
  twitterImage: 'https://uimire/social-card.png',
  twitterCard: 'summary_large_image'
})
</script>

<template>
  <div>
    <Header class="noprint" />

    <UMain>
      <NuxtPage />
    </UMain>

    <Footer class="noprint" />

    <UNotifications />
  </div>
</template>

<style>
.printonly {
  display: none;
}

@media print {
  .printonly {
    display: block;
  }

  .noprint {
    display: none;
  }
}

.page-enter-active,
.page-leave-active {
  transition: all 0.1s;
}

.page-enter-from,
.page-leave-to {
  opacity: 0;
  filter: blur(5px);
}
</style>